<template>
  <div class="form-row">
    <div v-if="routerBack" class="col-6 col-md-5">
      <b-button
        variant="light"
        @click="$router.push({ name: routerBack })"
        class="btn btn-light float-right btn-block"
      >
        Voltar
      </b-button>
    </div>
    <div :class="routerBack ? 'col-6 col-md-7' : 'col-12'">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="variant"
        class="mr-2"
        type="submit"
        block
        :disabled="submited"
      >
        <div v-if="submited">
          <b-spinner small variant="light" /> {{ subtext }}...
        </div>
        <div v-else>{{ text }}</div>
      </b-button>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BButton, BSpinner } from 'bootstrap-vue';
export default {
  props: ['submited', 'text', 'subtext', 'variant', 'routerBack'],
  components: {
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
}
</script>