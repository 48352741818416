<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="form-row justify-content-end">
          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <input
                type="search"
                class="form-control d-inline-block"
                placeholder="Turma"
                v-model="filters.classroom"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <flat-pickr
                class="form-control"
                placeholder="Data das aulas"
                v-model="filters.date"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Unidade"
                :options="optionsUnits"
                v-model="filters.unit"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Turno"
                :options="optionsShifts"
                v-model="filters.shift"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                v-model="filters.signature"
                :options="signatureOptions"
                placeholder="Assinaturas"
                label="label"
                :clearable="false"
                :reduce="(option) => option.value"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                v-model="filters.report"
                :options="reportOptions"
                placeholder="Relatório"
                label="label"
                :reduce="(option) => option.value"
              />
            </div>
          </div>
        </div>
        <div class="form-row mt-1">
          <div class="col-md-11">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                ref="selectTeacher"
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.teachers"
                placeholder="Digite o nome do professor"
                :options="optionsTeachers"
                @search="fetchTeachers"
                multiple
              >
                <span
                  slot="no-options"
                  @click="$refs.selectTeacher.open = false"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-1">
            <button
              @click="search"
              style="padding: 14px !important"
              type="button"
              class="btn btn-primary btn-block"
            >
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <div class="form-row">
      <div class="col-md-3"></div>
      <div class="col-md-9"></div>
    </div>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                v-model="filters.perPage"
                :options="perPageOptions"
                @input="setPerPageSelected"
                :clearable="false"
                :searchable="false"
              />
            </div>
          </div>
        </div>
      </div>

      <b-table
        :no-border-collapse="true"
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        sort-by="start_time"
        :busy="loading"
        hover
        id="printMe"
        :tbody-tr-class="rowClass"
        empty-text="Nenhuma aula cadastrada até o momento."
      >
        <template #cell(actions)="{ item }">
          <div
            v-if="
              $can('Ponto do Professor - Concluir Aula', 'Pedagógico') &&
              !item.signature
            "
          >
            <feather-icon
              icon="Edit2Icon"
              @click="openModalSignature(item)"
              class="cursor-pointer"
              size="15"
              v-b-tooltip.hover.bottom="`Assinar Ponto`"
            />
          </div>
          <div
            v-if="
              $can('Ponto do Professor - Editar Aula', 'Pedagógico') &&
              item.signature
            "
          >
            <feather-icon
              icon="EditIcon"
              @click="openEditModalSignature(item)"
              class="cursor-pointer"
              size="15"
              v-b-tooltip.hover.bottom="`Assinar Ponto`"
            />
          </div>
        </template>

        <template #head(devolution_pilots)="data">
          <span v-html="data.label"></span>
        </template>
        <template #cell(discipline_title)="{ item }">
          <b-media vertical-align="center">
            <div class="d-block">
              {{ item.discipline_title }}
            </div>
            <small style="font-size: 0.9rem" class="text-secondary">{{
              item.course_title
            }}</small>
          </b-media>
        </template>

        <template #cell(date)="{ item }">
          {{ item.date | datePT() }}
        </template>

        <template #cell(classrooms)="{ item }">
          <span v-for="(element, index) in item.classrooms" :key="index">
            {{ element.classroom_name }}
          </span>
        </template>

        <template #cell(arrival_time)="{ item }">
          <span v-if="item.arrival_time">
            {{ item.arrival_time }} às {{ item.departure_time }}
          </span>
        </template>
        <template #cell(devolution_pilots)="{ item }">
          <div v-if="item.devolution_pilots">
            <span class="text-dark" v-if="item.devolution_pilots.code === 1"
              >Ok</span
            >
          </div>
        </template>

        <template #cell(signature)="{ item }">
          <span v-if="item.signature">
            {{ item.signature | datePT(true) }}
          </span>
        </template>

        <template #cell(start_time)="{ item }">
          <span v-if="item.start_time">
            {{ item.start_time }} às {{ item.end_time }}
          </span>
        </template>

        <template #cell(real_workload)="{ item }">
          <span v-if="item.real_workload">
            {{ convertDecimalHoursToTime(item.real_workload) }}
          </span>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              small
              class="align-middle"
              style="margin-right: 3px"
            ></b-spinner>
            <strong> carregando...</strong>
          </div>
        </template>
      </b-table>

      <b-modal
        id="modal-create-signature"
        modal-class="modal-create"
        no-close-on-backdrop
        no-close-on-esc
        @hidden="hideModal"
        title="Detalhes da aula"
        centered
        hide-footer
      >
        <b-form @submit.prevent="submitSignature">
          <div class="form-row mb-2">
            <div class="col-md-8">
              <div class="form-group">
                <label for="">Professor(a)</label>
                {{ selectedLesson.teacher_full_name }}
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Horário</label>
                {{ selectedLesson.start_time }} às
                {{ selectedLesson.end_time }}
              </div>
            </div>
            <div class="col-md-12">
              <b-media vertical-align="center">
                <div class="d-block">
                  {{ selectedLesson.discipline_title }}
                </div>
                <small style="font-size: 0.9rem" class="text-secondary">{{
                  selectedLesson.course_title
                }}</small>
              </b-media>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Horário chegada
                </label>
                <input
                  type="time"
                  v-model="formItem.arrival_time"
                  class="form-control"
                  placeholder="00:00"
                  :class="{
                    'is-invalid': $v.formItem.arrival_time.$error,
                  }"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Horário saída
                </label>
                <input
                  type="time"
                  v-model="formItem.departure_time"
                  class="form-control"
                  placeholder="00:00"
                  :class="{
                    'is-invalid': $v.formItem.departure_time.$error,
                  }"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Dev. pilots</label
                >
                <v-select
                  label="title"
                  required
                  v-model="formItem.devolution_pilots"
                  :options="optionsDevPilots"
                  :clearable="false"
                  :searchable="false"
                  :class="{
                    'is-invalid': $v.formItem.devolution_pilots.$error,
                  }"
                />
              </div>
            </div>
          </div>

          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-4 col-12">
              <button
                type="button"
                @click="hideModal"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submitedTeacherPoint"
                text="Salvar"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="filters.currentPage"
        :perPage="filters.perPage"
        :totalRows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormRow,
  BForm,
  BAvatar,
  BMedia,
  BSpinner,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import * as XLSX from "xlsx";

export default {
  components: {
    BAvatar,
    ButtonsActionsFooter,
    BMedia,
    BFormInput,
    flatPickr,
    CustomPaginateTable,
    BCard,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormRow,
    vSelect,
    BSpinner,
    BTooltip,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: false,
      selectedLesson: {},
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
        {
          title: "300",
          value: 300,
        },
      ],
      optionsModalities: [],
      optionsShifts: [],
      optionsDatasheets: [],
      optionsCourseSales: [],
      optionsScholarities: [],
      optionsUnits: [],
      optionsDevPilots: [
        { code: 1, title: "Sim" },
        { code: 0, title: "Não" },
      ],
      submitedTeacherPoint: false,
      btnSearch: false,
      filters: {
        classroom: "",
        date: [],
        unit: "",
        teachers: [],
        shift: "",
        currentPage: 1,
        perPage: 100,
        signature: "all_signatures",
        report: undefined,
        type: "",
      },
      user: {},
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      optionsTeachers: [],
      tableColumns: [
        {
          key: "actions",
          label: "#",
          sortable: false,
          class: "text-left",
          thStyle: "width: 40px",
        },
        {
          key: "teacher_full_name",
          label: "Professor",
          sortable: false,
          class: "text-left",
          thStyle: "width: 200px;display: table-cell;",
        },
        {
          key: "discipline_title",
          label: "Disciplina/Curso",
          sortable: false,
          thStyle: "width: 340px;display: table-cell;",
        },
        {
          key: "date",
          label: "Data",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "classrooms",
          label: "Turmas",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "unit_name",
          label: "Unidade",
          sortable: true,
          class: "text-center",
          thStyle: "width: 120px",
        },
        {
          key: "start_time",
          label: "Horário",
          sortable: true,
          class: "text-center",
          thStyle: "width: 120px",
        },
        {
          key: "signature",
          label: "Assinado",
          sortable: false,
          class: "text-center",
          thStyle: "width: 115px;display: table-cell;",
        },
        {
          key: "user_signature_name",
          label: "Responsável",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "arrival_time",
          label: "Período",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "real_workload",
          label: "Tempo",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "teacher_points_report_id",
          label: "Relatório (ID)",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "devolution_pilots",
          label: "<i class='bi bi-pencil'></i>",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      signatureOptions: [
        { label: "Todos", value: "all_signatures" },
        { label: "Assinados", value: "signed" },
        { label: "Não Assinados", value: "unsigned" },
      ],
      reportOptions: [
        { label: "Todos", value: undefined },
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
      item: {
        departure_time: "",
        arrival_time: "",
        signature: "",
        devolution_pilots: "",
      },
      formItem: {
        departure_time: "",
        arrival_time: "",
        devolution_pilots: "",
      },
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.filters.date = `${moment()
      .startOf("month")
      .format("YYYY-MM-DD")} até ${moment()
      .endOf("month")
      .format("YYYY-MM-DD")}`;

    this.getData();
  },
  validations: {
    formItem: {
      departure_time: {
        required,
      },
      arrival_time: {
        required,
      },
      devolution_pilots: {
        required,
      },
    },
  },
  methods: {
    convertDecimalHoursToTime(decimalHours) {
      const hours = Math.floor(decimalHours);
      const minutes = Math.round((decimalHours - hours) * 60);
      if (hours === 0) return `${minutes}min`;
      if (minutes === 0) return `${hours}h`;

      return `${hours}h ${minutes}min`;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.signature) return "table-success";
    },
    openEditModalSignature(item) {
      this.formItem = {
        departure_time: item.departure_time,
        arrival_time: item.arrival_time,
        devolution_pilots: item.devolution_pilots,
      };
      this.selectedLesson = item;
      this.$bvModal.show("modal-create-signature");
    },
    async fetchTeachers(term) {
      if (term.length > 2) {
        this.optionsTeachers = await this.$store.dispatch(
          "Teacher/search",
          term
        );
      }
    },
    async fetchShifts() {
      this.optionsShifts = await this.$store.dispatch("Shift/forSelect");
    },
    async fetchUnits() {
      this.optionsUnits = await this.$store.dispatch("Unit/forSelect");
    },
    openModalSignature(item) {
      this.selectedLesson = item;
      this.$bvModal.show("modal-create-signature");
    },
    async generateReport() {
      this.$swal({
        title: "Confirmação necessária",
        text: `Tem certeza de que deseja gerar o relatório de ${this.totalRows} registros? Esta ação pode levar alguns minutos.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, gerar relatório",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.$store.dispatch(
              "Grid/generateTeacherPointsReport",
              this.filters
            );
            this.$swal({
              title: "Relatório gerado!",
              text: "O relatório foi gerado com sucesso.",
              icon: "success",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } catch (error) {
            this.$swal({
              title: "Erro ao gerar o relatório",
              text: "Ocorreu um erro durante a geração do relatório. Por favor, tente novamente.",
              icon: "error",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        }
      });
    },

    async fetchDatasheets(term) {
      if (term.length > 2) {
        this.optionsDatasheets = await this.$store.dispatch(
          "Datasheet/search",
          term
        );
      }
    },
    async fetchCourseSale(term) {
      this.optionsCourseSales = await this.$store.dispatch(
        "CourseSale/forSelect",
        term
      );
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },

    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
      this.getData();
    },

    updatePage() {
      this.getData();
    },
    submitSignature() {
      this.submitedImport = true;
      this.$v.formItem.$touch();

      if (!this.$v.formItem.$error) {
        this.formItem.uuids = [this.selectedLesson.uuid];

        this.$store
          .dispatch("Grid/signatureMultipleTeacherPoints", this.formItem)
          .then(() => {
            this.notifyDefault("success");
            this.hideModal();
            this.getData();
          });
      }
    },
    hideModal() {
      this.$bvModal.hide("modal-create-signature");
      Object.keys(this.formItem).forEach((key) => {
        this.formItem[key] = "";
      });
      this.selectedLesson = {};
    },
    async downloadCSV() {
      try {
        const toCSV = this.dataItems.map((item) => {
          return {
            ID: item.id,
            "Data da Aula": moment(item.date).format("DD/MM/YYYY"),
            "Horário de Início": item.start_time,
            "Horário de Término": item.end_time,
            "Carga Horária Real":
              item.real_workload !== null ? `${item.real_workload}h` : "-",
            "Nome do Professor": item.teacher_full_name,
            Unidade: item.unit_name,
            Disciplina: item.discipline_title,
            Categoria: item.category_name,
            "Salas de Aula": item.classrooms
              .map((c) => c.classroom_name)
              .join(", "),
            Assinatura: item.signature
              ? moment(item.signature).format("DD/MM/YYYY HH:mm:ss")
              : "Sem Assinatura",
            Chegada: item.arrival_time || "Não Informado",
            Saída: item.departure_time || "Não Informado",
            "Devolução de Pilotos": item.devolution_pilots
              ? item.devolution_pilots.title
              : "Não Informado",
          };
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(toCSV);
        XLSX.utils.book_append_sheet(wb, ws, "Relatório de Aulas");
        XLSX.writeFile(
          wb,
          `relatorio-aulas-${moment().format("DD-MM-YYYY_HH-mm-ss")}.xlsx`
        );
      } catch (error) {
        console.error("Erro ao exportar para CSV:", error);
      }
    },

    async getData() {
      this.loading = true;
      this.$store
        .dispatch("Grid/getAllLessonsTeacherPoints", this.filters)
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.filters.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.fetchShifts();

    this.fetchUnits();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:btn-print-out", (res) => {
      localStorage.removeItem("printData");
      localStorage.setItem("printData", JSON.stringify(this.filters));

      const url = this.$router.resolve({
        name: "teacher-points-list-print",
      }).href;

      window.open(url, "_blank");
    });

    this.$root.$on("open:export-table", () => {
      this.$swal({
        title: "Exportar pontos de professores",
        text: "O arquivo será baixado assim que confirmar.",
        iconHtml: '<i class="bi bi-download" style="font-size: 1.5rem;"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.downloadCSV();
        }
      });
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@media print {
  .main-menu,
  .paginate-area,
  .content-header-breadcrumb,
  .table tbody tr > td:first-child,
  .table thead tr > th:first-child {
    display: none;
  }
  .card {
    .m-2 {
      display: none;
    }
  }
  .table tbody tr > td {
    border-right: 1px solid #eff0f0;
  }
}
</style>
