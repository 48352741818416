var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-row" }, [
    _vm.routerBack
      ? _c(
          "div",
          { staticClass: "col-6 col-md-5" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn btn-light float-right btn-block",
                attrs: { variant: "light" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: _vm.routerBack })
                  },
                },
              },
              [_vm._v(" Voltar ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { class: _vm.routerBack ? "col-6 col-md-7" : "col-12" },
      [
        _c(
          "b-button",
          {
            directives: [
              {
                name: "ripple",
                rawName: "v-ripple.400",
                value: "rgba(255, 255, 255, 0.15)",
                expression: "'rgba(255, 255, 255, 0.15)'",
                modifiers: { 400: true },
              },
            ],
            staticClass: "mr-2",
            attrs: {
              variant: _vm.variant,
              type: "submit",
              block: "",
              disabled: _vm.submited,
            },
          },
          [
            _vm.submited
              ? _c(
                  "div",
                  [
                    _c("b-spinner", { attrs: { small: "", variant: "light" } }),
                    _vm._v(" " + _vm._s(_vm.subtext) + "... "),
                  ],
                  1
                )
              : _c("div", [_vm._v(_vm._s(_vm.text))]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }